import Rails from '@rails/ujs';

import 'font-awesome/css/font-awesome.css';
import { removeElementByMorse, getQueryParam, getLoginStatus } from './utils/morse';

export function start() {
  require.context('../images/', true);

  try {
    Rails.start();

    window.onload = () => {
      const fromMorse = getQueryParam('fromMorse')
      const status = getLoginStatus()
      // 从Morse APP登录，并且cookie中没有包含session id
      if(fromMorse && !status) {
        const pathname = window.location.pathname
        if (pathname === '/auth/sign_in') {
          const btnDom = document.getElementsByClassName('button-openid_connect')[0]
          // 从Web页面跳转
          if (getQueryParam('web')) {
            let btnHref = btnDom.getAttribute("href")
            if (btnHref) {
              btnHref += `?cookie=${fromMorse}`
              btnDom.setAttribute("href", btnHref)
            }
          }
          // 自动登录
          btnDom.click()
        } else {
          // 跳转登录页
          let redirectUrl = '/auth/sign_in?fromMorse=' + fromMorse
          if (getQueryParam('theme')) {
            redirectUrl += `&theme=${getQueryParam('theme')}`
          }
          if (getQueryParam('web')) {
            redirectUrl += `&web=${getQueryParam('web')}`
          }
          window.location.href = redirectUrl
        }
      }

      removeElementByMorse()
    }

  } catch (e) {
    // If called twice
  }
}
