/* eslint-disable react/self-closing-comp */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { defineMessages, injectIntl } from 'react-intl';

import { IconButton } from 'mastodon/components/icon_button';

const messages = defineMessages({
  back: { id: 'column_back_button.label', defaultMessage: 'Back' },
});

class HtmlContentModal extends PureComponent {

  static propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  render () {
    const { title, content, intl, onClose } = this.props;


    return (
      <div className='modal-root__modal html-content-modal'>
        <div className='html-content-modal__navigation' role='presentation'>
          <IconButton className='html-content-modal__back' title={intl.formatMessage(messages.back)} icon='chevron-left' onClick={onClose} size={16} />
          <div className='html-content-modal__title'>
            {title}
          </div>
        </div>

        <div className='html-content-modal__container' dangerouslySetInnerHTML={{__html: content}}></div>
      </div>
    );
  }

}

export default injectIntl(HtmlContentModal);
