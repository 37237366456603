// 判断是否来自于Morse
// export const fromMorse = window.AndroidApi != null || window.webkit && window.webkit.messageHandlers != null;
export const fromMorse = window.AndroidApi != null || window.webkit && window.webkit.messageHandlers != null || getQueryParam('fromMorse') != null;

// 如果从Morse进入，则移除元素
export function removeElementByMorse() {
  if (!document.querySelectorAll) return;
  if (fromMorse) {
    const nodeList = document.querySelectorAll("*[data-hidden='from-morse']")
    for (let i = 0; i < nodeList.length; i++) {
      nodeList[i].remove()
    }
  } else {
    const nodeList = document.querySelectorAll("*[data-hidden='no-morse']")
    for (let i = 0; i < nodeList.length; i++) {
      nodeList[i].remove()
    }
  }

}

let startChatDate

// 发起聊天
export function startChat(username) {
  const now = new Date().getTime()

  if (startChatDate != null && (now - startChatDate) <= 1000) return

  startChatDate = now
  // 频率控制
  if (window.AndroidApi) {
    window.AndroidApi.startChat(username)
  } else if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.startChat) {
    window.webkit.messageHandlers.startChat.postMessage(username)
  }
}

// 获取URL中的参数
export function getQueryParam(key) {
  const regex = new RegExp(`[?&]${key}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1]);
}

// 获取登录状态
export function getLoginStatus() {
  const element = document.getElementById('initial-state');
  const initialState = element?.textContent && JSON.parse(element.textContent);
  return initialState?.meta && initialState.meta.access_token
}
